import FooterData from "../variables/variables.json";
import TranslatedMessage from "./TranslatedMessage";
import Image from "next/image";
import Link from "next/link";

export function Footer(props) {
	// PROPS VARIABLE
	let propsMiasto = props.miasto;
	let propsPodatek = props.podatek;

	let klauzulaHref;

	switch (propsMiasto) {
		case "bialystok":
			klauzulaHref =
				"https://przyjazne-deklaracje.pl/resources/dokumenty/bialystok/klauzula-informacyjna/klauzula-informacyjna-UMwB.pdf";
			break;
		default:
			klauzulaHref =
				"https://resources.przyjazne-deklaracje.pl/RESOURCES/Klauzula_informacyjna_o4b.pdf";
			break;
	}

	return (
		<>
			<footer className="footer pd-section">
				<div className="pd-section__inner-wrap">
					<div className="footer-row one" role="contentinfo">
						{FooterData.miasta[propsMiasto].pages.includes("transportowe") && (
							<div className="footer-column">
								<h2 id="taxes-title">Podatki</h2>
								<ul aria-labelledby="taxes-title" className="footer-list">
									<li>
										<Link href="/podatek-od-nieruchomosci/">Podatek od nieruchomości</Link>
									</li>
									<li>
										<Link href="/podatek-rolny/">Podatek rolny</Link>
									</li>
									<li>
										<Link href="/podatek-lesny/">Podatek leśny</Link>
									</li>
									<li>
										<Link href="/podatek-od-srodkow-transportowych/">
											Podatek od środków transportowych
										</Link>
									</li>
								</ul>
							</div>
						)}
						{FooterData.miasta[propsMiasto].pages.includes("odpadykomunalne") && (
							<div className="footer-column">
								<h2 id="fees-title">Opłaty</h2>
								<ul className="footer-list vertical-bottom-link">
									<li>
										<Link href="/oplata-za-gospodarowanie-odpadami-komunalnymi/">
											Opłata za gospodarowanie odpadami komunalnymi
										</Link>
									</li>
									<li>
										<Link href="https://przyjazne-deklaracje.pl">
											Szukasz podatku w innym mieście? Kliknij tutaj
										</Link>
									</li>
								</ul>
							</div>
						)}
						<div className="footer-column">
							<h2>
								<Link href="/kontakt/" id="contact-title">
									Kontakt
								</Link>
							</h2>
							{!FooterData.miasta[propsMiasto].pages.includes("odpadykomunalne") && (
								<ul className="footer-list link-to-bottom">
									<li>
										<Link href="https://przyjazne-deklaracje.pl">
											Szukasz podatku w innym mieście? Kliknij tutaj
										</Link>
									</li>
								</ul>
							)}
						</div>
					</div>

					<div className="footer-row two">
						<nav aria-label="Secondary links">
							<ul className="footer-list">
								<li className="full-width">
									<img
										src="https://przyjazne-deklaracje.pl/resources/images/icons/general/files/icon-footer-info.svg"
										alt=""
									/>
									<div className="links-wrapper">
										{propsMiasto === "bialystok" && (
											<Link href="https://przyjazne-deklaracje.pl/resources/dokumenty/bialystok/klauzula-informacyjna/klauzula-informacyjna-UMwB.pdf">
												Szczegółowe informacje o przetwarzaniu danych osobowych przez Prezydenta
												Białegostoku (PDF, 0.1 MB)
											</Link>
										)}
										<Link href="https://resources.przyjazne-deklaracje.pl/RESOURCES/Klauzula_informacyjna_o4b.pdf">
											Informacja o przetwarzaniu danych osobowych przez O4B Sp. z o.o. (PDF, 0.8 MB)
										</Link>
									</div>
								</li>
								<li>
									<Link href="/deklaracja-dostepnosci/">
										<img
											src="https://przyjazne-deklaracje.pl/resources/images/icons/general/files/icon-footer-availability.svg"
											alt=""
										/>
										Deklaracja dostępności
									</Link>
								</li>
								<li>
									<Link
										href="https://przyjazne-deklaracje.pl/najczesciej-zadawane-pytania-faq/"
										target="_blank"
										aria-label="Najczęściej zadawane pytania (FAQ). Tutaj znajdziesz odpowiedzi na pytania o przygotowanie i składanie deklaracji podatkowych online, wykorzystując algorytmy do doboru stawek i obliczania podatków.  Link otworzy się w nowej karcie"
									>
										<img
											src="https://przyjazne-deklaracje.pl/resources/images/icons/general/files/icon-footer-faq.svg"
											alt=""
										/>
										FAQ
									</Link>
								</li>
								<li>
									<Link href="https://przyjazne-deklaracje.pl/resources/dokumenty/przyjazne-deklaracje/regulamin/regulamin_korzystania_z_przyjazne-deklaracje-pl.pdf">
										<img
											src="https://przyjazne-deklaracje.pl/resources/images/icons/general/files/icon-footer-statute.svg"
											alt=""
										/>
										Regulamin O4B (PDF, 0.1 MB)
									</Link>
								</li>
								<li>
									<Link href="/mapa-strony">
										<img
											src="https://przyjazne-deklaracje.pl/resources/images/icons/general/files/icon-footer-sitemap.svg"
											alt=""
										/>
										Mapa strony
									</Link>
								</li>
							</ul>
						</nav>
						<nav className="third-nav">
							<ul className="footer-list">
								<li className="socials">
									<Link href="https://www.facebook.com/PrzyjazneDeklaracje/">
										<img
											src="https://przyjazne-deklaracje.pl/resources/images/icons/general/files/icon-footer-fb.svg"
											alt=""
										/>
									</Link>
									<Link href="https://www.instagram.com/przyjazne_deklaracje/">
										<img
											src="https://przyjazne-deklaracje.pl/resources/images/icons/general/files/icon-footer-instagram.svg"
											alt=""
										/>
									</Link>
									<Link href="https://www.youtube.com/channel/UC4PoPhe0YqKX_Z8uT01dHxw">
										<img
											src="https://przyjazne-deklaracje.pl/resources/images/icons/general/files/icon-footer-yt.svg"
											alt=""
										/>
									</Link>
								</li>
								<li>
									<div className="footer-list-column"></div>
									<div className="footer-list-column">
										<Link href="https://o4b.pl" className="providedBy">
											<TranslatedMessage id={"miasta." + propsMiasto + ".footer.providedBy"} />
										</Link>
									</div>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</footer>
		</>
	);
}

export default Footer;
