import { useRouter } from "next/router";
import NazwaMiasta from "../../variables/variables.json";

export function City(props) {
  const router = useRouter();

  // let city = router.query.city
  // let siteCode = router.query.siteCode

  let Miasto = "GW_KONSKOWOLA";

  return <span>{NazwaMiasta.miasta[Miasto].name[props.name]}</span>;
}

export default City;
