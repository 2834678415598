// NavigationScript.js
import { useEffect } from "react";

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

const NavigationScript = () => {
  useEffect(() => {
    var appsMenuOverflow = document.querySelector(".pd-navigation__overflow");
    var appsMenuItems = document.querySelectorAll("#appmenu > li");
    var appsMenuLink = document.querySelectorAll("#appmenu > li > a");
    var subMenuItems = document.querySelectorAll("#appmenu li li");
    var subMenuLink = document.querySelectorAll("#appmenu > li li a");
    var subSubMenu = document.querySelectorAll("#appmenu > li > ul");
    var subSubMenuItems = document.querySelectorAll("#appmenu > li > ul > li");

    var subSubSubMenuItems = document.querySelectorAll(
      "#appmenu > li ul li.hasSubMenu ul li"
    );

    // console.log(subSubMenuItems)

    Array.prototype.forEach.call(subSubMenu, function (el, i) {
      // console.log(el) // e-Usługi, Podatki <ul>
      let subSubMenuItems = el.querySelectorAll(":scope > li");
      // console.log(subSubMenuItems) // e-Usługi, Podatki <li>
      Array.prototype.forEach.call(subSubMenuItems, function (el, i) {
        // console.log(el) // e-Usługi, Podatki <li>
        let subSubMenuMenu = el.querySelector(":scope > ul");
        let height = 57;
        let width = 274;
        if (el.classList.contains("hasSubMenu")) {
          let calcHeight = (i + 1) * height - height;
          // console.log(calcHeight)
          // console.log(subSubMenuMenu.style.top = calcHeight + "px")
          subSubMenuMenu.style.top = calcHeight + "px";
        }
      });
    });

    var keys = {
      tab: 9,
      enter: 13,
      esc: 27,
      space: 32,
      left: 37,
      up: 38,
      right: 39,
      down: 40,
    };
    var currentIndex, subIndex;

    var gotoIndex = function (idx) {
      if (idx == appsMenuItems.length) {
        idx = 0;
      } else if (idx < 0) {
        idx = appsMenuItems.length - 1;
      }
      appsMenuItems[idx].focus();
      currentIndex = idx;
    };

    var gotoSubIndex = function (menu, idx) {
      var items = menu.querySelectorAll("li:not(.subSubMenu li)");
      if (idx == items.length) {
        idx = 0;
      } else if (idx < 0) {
        idx = items.length - 1;
      }
      items[idx].focus();
      subIndex = idx;
    };

    var gotoSubSubIndex = function (menu, idx) {
      var items = menu.querySelectorAll(".subSubMenu li");
      if (idx == items.length) {
        idx = 0;
      } else if (idx < 0) {
        idx = items.length - 1;
      }
      items[idx].focus();
      subIndex = idx;
    };

    appsMenuOverflow.addEventListener("click", function (event) {
      for (var i = 0; i < appsMenuItems.length; i++) {
        appsMenuItems[i].setAttribute("aria-expanded", "false");
        if (appsMenuItems[i].getAttribute("aria-expanded") == null) {
          appsMenuItems[i].getAttribute("aria-expanded") == null;
        }
      }
      for (var i = 0; i < subSubMenuItems.length; i++) {
        subSubMenuItems[i].setAttribute("aria-expanded", "false");
        if (subSubMenuItems[i].getAttribute("aria-expanded") == null) {
          subSubMenuItems[i].getAttribute("aria-expanded") == null;
        }
      }
      appsMenuOverflow.classList.remove("active");
      event.preventDefault();
    });

    // THIS
    Array.prototype.forEach.call(appsMenuItems, function (el, i) {
      if (0 == i) {
        el.setAttribute("tabindex", "0");
        el.addEventListener("focus", function () {
          currentIndex = 0;
        });
      } else {
        el.setAttribute("tabindex", "-1");
      }
      // el.addEventListener("focus", function () {
      //   Array.prototype.forEach.call(appsMenuItems, function (el, i) {
      //     el.setAttribute("aria-expanded", "false")
      //   })
      // })
      el.addEventListener("click", function (event) {
        if (
          this.getAttribute("aria-expanded") == "false" ||
          this.getAttribute("aria-expanded") == null
        ) {
          Array.prototype.forEach.call(appsMenuItems, function (el, i) {
            el.setAttribute("aria-expanded", "false");
          });
          Array.prototype.forEach.call(subSubMenuItems, function (el, i) {
            el.setAttribute("aria-expanded", "false");
          });
          this.setAttribute("aria-expanded", "true");
          if (el.childNodes.length === 2) {
            appsMenuOverflow.classList.add("active");
          }
        } else {
          this.setAttribute("aria-expanded", "false");
          appsMenuOverflow.classList.remove("active");
        }
        if (el.childNodes[0].href) {
          window.location.href = el.childNodes[0].href;
        }
        return false;
      });
      // el.addEventListener("mouseenter", function (event) {
      //   if (
      //     this.getAttribute("aria-expanded") == "false" ||
      //     this.getAttribute("aria-expanded") == null
      //   ) {
      //     this.setAttribute("aria-expanded", "true")
      //   } else {
      //     this.setAttribute("aria-expanded", "false")
      //   }
      //   if (el.childNodes[0].href) {
      //     window.location.href = el.childNodes[0].href
      //   }
      //   event.preventDefault()
      //   return false
      // })
      // el.addEventListener("mouseleave", function (event) {
      //   this.setAttribute("aria-expanded", "false")
      //   event.preventDefault()
      //   return false
      // })

      el.addEventListener("keydown", function (event) {
        switch (event.keyCode) {
          case keys.right:
            gotoIndex(currentIndex + 1);
            break;
          case keys.left:
            gotoIndex(currentIndex - 1);
            break;
          // case keys.tab:
          //   if (event.shiftKey) {
          //     gotoIndex(currentIndex - 1);
          //   } else {
          //     gotoIndex(currentIndex + 1);
          //   }
          //   break;

          // case keys.tab:
          //   if (event.shiftKey) {
          //     if (currentIndex === 0) {
          //       event.preventDefault();
          //       gotoIndex(currentIndex - 1);
          //     }
          //   } else {
          //     if (currentIndex === appsMenuItems.length - 1) {
          //       event.preventDefault();
          //       gotoIndex(0);
          //     }
          //   }
          //   break;
          case keys.enter:
          case keys.space:
            this.click();
            if (el.childNodes.length === 1) {
              el.childNodes[0].childNodes[0].click();
              this.click();
              // gotoIndex(currentIndex - 1)
              // el.focus()
            } else {
              this.click();
            }
          case keys.down:
            if (el.childNodes.length > 1) {
              this.click();
              gotoSubIndex(this.querySelector("ul"), 0);
            }
            break;
          case keys.up:
            if (el.childNodes.length > 1) {
              this.click();
              gotoSubIndex(this.querySelector("ul"), 0);
            }
            break;
          case keys.esc:
            // document.querySelector('a[href="#related"]').focus()
            Array.prototype.forEach.call(appsMenuItems, function (el, i) {
              el.setAttribute("aria-expanded", "false");
            });
            Array.prototype.forEach.call(subSubMenuItems, function (el, i) {
              el.setAttribute("aria-expanded", "false");
            });
            this.setAttribute("aria-expanded", "false");
            appsMenuOverflow.classList.remove("active");
            return false;
        }
        // event.preventDefault()
      });
    });

    Array.prototype.forEach.call(subSubMenuItems, function (el, i) {
      el.setAttribute("tabindex", "-1");
      el.addEventListener("keydown", function (event) {
        switch (event.keyCode) {
          case keys.tab:
            if (event.shiftKey) {
              gotoIndex(currentIndex - 1);
            } else {
              gotoIndex(currentIndex + 1);
            }
            break;
          case keys.right:
            // gotoIndex(currentIndex + 1)
            // Array.prototype.forEach.call(appsMenuItems, function (el, i) {
            //   el.setAttribute("aria-expanded", "false")
            // })
            if (el.classList.contains("hasSubMenu")) {
              this.setAttribute("aria-expanded", "true");
              gotoSubSubIndex(this.parentNode, subIndex + 1);
            }
            break;
          case keys.left:
            break;
          case keys.esc:
            Array.prototype.forEach.call(appsMenuItems, function (el, i) {
              el.setAttribute("aria-expanded", "false");
            });
            Array.prototype.forEach.call(subSubMenuItems, function (el, i) {
              el.setAttribute("aria-expanded", "false");
            });
            this.setAttribute("aria-expanded", "false");
            appsMenuOverflow.classList.remove("active");
            break;
          case keys.down:
            gotoSubIndex(this.parentNode, subIndex + 1);
            Array.prototype.forEach.call(subSubMenuItems, function (el, i) {
              el.setAttribute("aria-expanded", "false");
            });
            break;
          case keys.up:
            gotoSubIndex(this.parentNode, subIndex - 1);
            Array.prototype.forEach.call(subSubMenuItems, function (el, i) {
              el.setAttribute("aria-expanded", "false");
            });
            break;
          case keys.enter:
          case keys.space:
            if (el.classList.contains("hasSubMenu")) {
              this.setAttribute("aria-expanded", "true");
              gotoSubSubIndex(this.parentNode, subIndex + 1);
            } else {
              window.location.href = el.querySelector(":scope a").href;
            }
            break;
        }
        event.preventDefault();
        event.stopPropagation();
        return false;
      });
      if (el.classList.contains("hasSubMenu")) {
        el.addEventListener("click", function (event) {
          if (
            this.getAttribute("aria-expanded") == "false" ||
            this.getAttribute("aria-expanded") == null
          ) {
            Array.prototype.forEach.call(subSubMenuItems, function (el, i) {
              el.setAttribute("aria-expanded", "false");
            });
            this.setAttribute("aria-expanded", "true");
            if (el.childNodes.length === 2) {
            }
          } else {
            this.setAttribute("aria-expanded", "false");
          }
          if (el.childNodes[0].href) {
            window.location.href = el.childNodes[0].href;
          }
          event.stopPropagation();
          return false;
        });
      } else {
        el.addEventListener("click", function () {
          window.location.href = el.querySelector(":scope a").href;
        });
      }
    });

    Array.prototype.forEach.call(subSubSubMenuItems, function (el, i) {
      el.setAttribute("tabindex", "-1");
      el.addEventListener("keydown", function (event) {
        switch (event.keyCode) {
          case keys.tab:
            if (event.shiftKey) {
              gotoIndex(currentIndex - 1);
            } else {
              gotoIndex(currentIndex + 1);
            }
            break;
          case keys.right:
            break;
          case keys.left:
            this.parentElement.parentElement.focus();
            break;
          case keys.esc:
            Array.prototype.forEach.call(appsMenuItems, function (el, i) {
              el.setAttribute("aria-expanded", "false");
            });
            Array.prototype.forEach.call(subSubMenuItems, function (el, i) {
              el.setAttribute("aria-expanded", "false");
            });
            this.setAttribute("aria-expanded", "false");
            appsMenuOverflow.classList.remove("active");
            break;
          case keys.down:
            gotoSubSubIndex(this.parentNode, subIndex + 1);
            break;
          case keys.up:
            gotoSubSubIndex(this.parentNode, subIndex - 1);
            break;
          case keys.enter:
          case keys.space:
            window.location.href = this.querySelector(":scope a").href;
            break;
        }
        event.preventDefault();
        event.stopPropagation();
        return false;
      });
    });

    // var searchLinks = document.querySelectorAll(".pd-search__link")

    var subSubSubMenuItems = document.querySelectorAll(
      "#appmenu > li ul li.hasSubMenu ul li"
    );

    // Array.prototype.forEach.call(searchLinks, function (el, i) {
    //   el.addEventListener("click", function (event) {
    //     // window.location.href = el.querySelector(":scope a").href;
    //     console.log(el)
    //     event.preventDefault()
    //     event.stopPropagation()
    //   })
    // })

    let searchNavigation = document.getElementsByClassName(
      "pd-navigation__search"
    )[0];
    let searchContainer = document.getElementsByClassName(
      "pd-search__container"
    )[0];
    let searchButtonContainer = document.getElementsByClassName(
      "pd-search__button__container"
    )[0];
    let searchButton = document.getElementsByClassName("pd-search__button")[0];
    let searchInput = document.getElementsByClassName("pd-search__input")[0];

    // searchButtonContainer.addEventListener("click", function (event) {
    //   event.stopPropagation()
    // })

    if (searchInput) {
      searchInput.addEventListener("click", function (event) {
        event.stopPropagation();
      });
    }

    // searchContainer.addEventListener("click", function (event) {
    // searchNavigation.setAttribute("aria-expanded", "true")
    // console.log(this)
    // event.preventDefault()
    // })

    // searchButton.addEventListener("click", function (event) {
    // if (
    //   this.getAttribute("aria-pressed") == "false" ||
    //   this.getAttribute("aria-pressed") == null
    // ) {
    //   searchNavigation.setAttribute("aria-expanded", "false")
    // }
    // if (this.getAttribute("aria-pressed") == "true") {
    //   if (searchNavigation.getAttribute("aria-expanded") == "true") {
    //     searchNavigation.setAttribute("aria-expanded", "false")
    //   }
    // }
    // event.stopPropagation()
    // })

    // MOBILE MENU
    const targetElement = document.querySelector(".pd-navigation__menu");

    var isOpen = false;

    document
      .querySelector(".pd-navigation__button__mobile")
      .addEventListener("click", navToggle);

    function openNav() {
      // console.log("openNav")
      // document
      //   .querySelector("html")
      //   .classList.toggle("pd-navigation__overflow__mobile")
      disableBodyScroll(targetElement);

      document
        .querySelector(".pd-navigation__button__mobile")
        .classList.add("active");
      document.querySelector(".pd-navigation").classList.add("active");
    }

    function closeNav() {
      // console.log("closeNav")
      // document
      //   .querySelector("html")
      //   .classList.toggle("pd-navigation__overflow__mobile")
      enableBodyScroll(targetElement);
      clearAllBodyScrollLocks(targetElement);

      document
        .querySelector(".pd-navigation__button__mobile")
        .classList.remove("active");
      document.querySelector(".pd-navigation").classList.remove("active");
    }

    function navToggle() {
      if (isOpen) {
        isOpen = false;
        closeNav();
      } else {
        isOpen = true;
        openNav();
      }
    }

    const menuItems = document.querySelectorAll("nav a");

    menuItems.forEach((el) => el.addEventListener("click", activeMenu));

    function activeMenu() {
      menuItems.forEach((el) => el.classList.remove("active"));
      this.classList.add("active");
      enableBodyScroll(targetElement);
      clearAllBodyScrollLocks(targetElement);

      // document
      //   .querySelector("html")
      //   .classList.remove("pd-navigation__overflow__mobile")
      document
        .querySelector(".pd-navigation__button__mobile")
        .classList.remove("active");
      document.querySelector(".pd-navigation").classList.remove("active");
    }
  }, []);

  return null;
};

export default NavigationScript;
