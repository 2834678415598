import { Link } from "react-scroll";
import classNames from "classname";

export function LinkTo(props) {
	return (
		<span
			className={classNames("link-to", {
				block: props.block,
			})}
		>
			<Link
				to={props.to}
				href={"#" + props.to}
				spy={true}
				smooth={true}
				offset={-124}
				duration={500}
				className={props.className}
			>
				{props.children}
			</Link>
		</span>
	);
}

export default LinkTo;
