import classNames from "classname";
import MDX from "./MDX";
import TranslatedMessage from "./TranslatedMessage";

const Panel = (props) => {
  return (
    <>
      <section
        className={classNames("pd-panel", {
          "pd-panel--center": props.center,
        })}
      >
        {props.title && (
          <h2 className="pd-panel__title">
            <TranslatedMessage id={props.title} />
          </h2>
        )}
        {props.mdx ? <MDX>{props.children}</MDX> : [props.children]}
      </section>
    </>
  );
};

export default Panel;
