import { FormattedMessage } from "react-intl";

const TranslatedMessage = (props) => {
  return (
    <FormattedMessage
      id={props.id}
      values={{
        br: <br />,
        ul: (chunks) => <ul>{chunks}</ul>,
        li: (chunks) => <li>{chunks}</li>,
        p: (chunks) => <p>{chunks}</p>,
        strong: (chunks) => <strong>{chunks}</strong>,
      }}
    />
  );
};

export default TranslatedMessage;
