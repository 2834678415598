import classNames from "classname";

export function TextColor(props) {
  return (
    <span
      className={classNames("text-color", {
        "text-color--primary": props.primary,
        "text-color--font": props.font,
      })}
    >
      {props.children}
    </span>
  );
}

export default TextColor;
