import { useRouter, withRouter } from "next/router";
import WCAG from "./WCAG";
import ActiveLink from "./ActiveLink";
import TranslatedMessage from "./TranslatedMessage";
import NavigationData from "../variables/variables.json";
import SearchCity from "./search/SearchCity";

import NavigationScript from "./js/NavigationScript";

export function Navigation(props) {
  const router = useRouter();

  // PROPS VARIABLE
  let propsMiasto = props.miasto;
  let propsPodatek = props.podatek;

  let propsType;
  let extendedUrl = "";
  let siteCode = router.query.siteCode;

  if (NavigationData.miasta[propsMiasto].index) {
    propsType = "/";
  } else if (NavigationData.miasta[propsMiasto].witryna) {
    propsType = "/witryna/";
    extendedUrl = "?siteCode=" + siteCode;
  } else {
    propsType = "/";
  }

  const MenuItemSub = (props) => (
    <li className="pd-navigation__menu-item" role="menuitem" tabIndex="-1">
      <ActiveLink
        className="pd-navigation__link"
        activeClassName="active"
        href={props.href}
      >
        {props.children}
      </ActiveLink>
    </li>
  );

  const MenuItemSubNoLink = (props) => (
    <li className="pd-navigation__menu-item" role="menuitem" tabIndex="-1">
      <span className="pd-navigation__link">{props.children}</span>
    </li>
  );

  return (
    <>
      <nav className="pd-navigation" aria-label="Główna nawigacja">
        <button className="pd-navigation__button__mobile" aria-label="Menu">
          <span className="hamburger"></span>
        </button>
        <ul
          className="pd-navigation__menu"
          role="menu"
          aria-label="functions"
          id="appmenu"
        >
          {NavigationData.miasta[propsMiasto].navigation.map(
            (navigation, index) =>
              navigation.submenu.available ? (
                <li
                  className="pd-navigation__menu-item"
                  role="menuitem"
                  aria-haspopup="true"
                  tabIndex="0"
                  aria-expanded="false"
                  key={index}
                >
                  <span className="pd-navigation__link">
                    <TranslatedMessage id={navigation.translatedMessage} />
                    <span className="chevron bottom"></span>
                  </span>
                  <ul className="pd-navigation__submenu" role="menu">
                    {navigation.submenu.list.map((list, index) =>
                      list.submenu ? (
                        <li
                          className="pd-navigation__menu-item hasSubMenu"
                          role="menuitem"
                          tabIndex="-1"
                          aria-haspopup="true"
                          aria-expanded="false"
                          key={index}
                        >
                          <span className="pd-navigation__link">
                            <TranslatedMessage id={list.translatedMessage} />
                            <span className="chevron right"></span>
                          </span>

                          <ul
                            className="pd-navigation__submenu subSubMenu"
                            role="menu"
                          >
                            {list.submenu.map((list, index) => (
                              <MenuItemSub
                                href={propsType + list.shortName + extendedUrl}
                                key={index}
                              >
                                <TranslatedMessage
                                  id={list.translatedMessage}
                                />
                              </MenuItemSub>
                            ))}
                          </ul>
                        </li>
                      ) : (
                        <MenuItemSub
                          href={propsType + list.shortName + extendedUrl}
                          key={index}
                        >
                          <TranslatedMessage id={list.translatedMessage} />
                        </MenuItemSub>
                      )
                    )}
                  </ul>
                </li>
              ) : (
                <MenuItemSub
                  href={propsType + navigation.shortName + extendedUrl}
                  key={index}
                >
                  <TranslatedMessage id={navigation.translatedMessage} />
                </MenuItemSub>
              )
          )}
        </ul>

        <WCAG />

        {NavigationData.miasta[propsMiasto].search.available && (
          <ul
            className="pd-navigation__menu pd-navigation__menu__wcag"
            role="menubar"
            aria-label="functions"
            id="appmenu"
          >
            <li
              className="pd-navigation__menu-item pd-navigation__search"
              role="menuitem"
              tabIndex="-1"
              aria-expanded="false"
            >
              <SearchCity miasto={props.miasto} name="search-city" />
            </li>
          </ul>
        )}
      </nav>

      <div className="pd-navigation__overflow"></div>

      <NavigationScript />
    </>
  );
}

export default withRouter(Navigation);
