function getAnchor(text) {
  return text
    .replace("https://www.youtube.com/watch?v=", "")
    .replace("https://youtube.com/watch?v=", "")
    .replace("www.youtube.com/watch?v=", "")
    .replace("youtube.com/watch?v=", "")

    .replace("https://www.youtu.be/", "")
    .replace("https://youtu.be/", "")
    .replace("www.youtu.be/", "")
    .replace("youtu.be/", "");
}

export function YouTube(props) {
  return (
    <>
      <div className="youtube-full-width">
        <iframe
          width="560"
          height="315"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          src={"https://www.youtube.com/embed/" + getAnchor(props.src)}
          className="youtube-full-width__video"
        ></iframe>
      </div>
    </>
  );
}

export default YouTube;
