import { useEffect } from "react";

const HeaderScript = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", function () {
        const body = document.querySelector("body");
        const header = document.querySelector("header");

        if (window.scrollY > 300) {
          body.classList.add("pd-navigation__body--margin-top");
          header.classList.add("fixed");
        } else {
          if (body) {
            body.classList.remove("pd-navigation__body--margin-top");
          }
          if (header) {
            header.classList.remove("fixed");
          }
        }
      });
    }
  }, []);

  return null;
};

export default HeaderScript;
