import dynamic from "next/dynamic";
import { useIntl } from "react-intl";
import classNames from "classname";
// import TranslatedMessage from "./TranslatedMessage"

import AccordionVariables from "../variables/variables.json";

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
	AccordionItemState,
} from "react-accessible-accordion";
import Script from "next/script";

const TranslatedMessage = dynamic(() => import("./TranslatedMessage"), {
	ssr: false,
});

const FAQ = (props) => {
	const intl = useIntl();

	let ComponentShow;

	// PROPS VARIABLE
	let propsMiasto = "PRZYJAZNEDEKLARACJE";
	let propsPodatek = props.section;

	let przyjazneVariable = AccordionVariables.miasta.PRZYJAZNEDEKLARACJE;
	let przyjazneTranslate = "miasta.PRZYJAZNEDEKLARACJE";

	// Declare an empty array
	const schemaData = [];
	const questions = [];

	// Loop through the Slice array
	AccordionVariables.miasta[propsMiasto].FAQ[propsPodatek].forEach((FAQ) => {
		questions.push({
			"@type": "Question",
			name: intl.formatMessage({
				id: FAQ.title,
			}),
			acceptedAnswer: {
				"@type": "Answer",
				text: intl.formatMessage({
					id: FAQ.tekst,
				}),
			},
		});
	});

	schemaData.push({
		"@context": "https://schema.org",
		"@type": "FAQPage",
		mainEntity: questions,
	});

	return (
		<>
			<div
				className={classNames("", {
					"accordion-template--primary": props.primary,
					"accordion-template--secondary": props.secondary,
					"accordion-template--number": props.number,
				})}
			>
				<Accordion allowMultipleExpanded allowZeroExpanded>
					{AccordionVariables.miasta[propsMiasto].FAQ[propsPodatek].map((FAQ, index) => (
						<AccordionItem uuid={index.toString()} key={index}>
							<AccordionItemHeading>
								<AccordionItemButton>
									<span className="accordion__title">
										<TranslatedMessage id={FAQ.title} />
									</span>
									<span className="accordion__action">
										<AccordionItemState>
											{({ expanded }) => (expanded ? "Zwiń odpowiedź" : "Rozwiń odpowiedź")}
										</AccordionItemState>
									</span>
									{props.number && <div className="accordion__number">{index + 1}</div>}
								</AccordionItemButton>
							</AccordionItemHeading>
							<AccordionItemPanel>
								<TranslatedMessage id={FAQ.tekst} />
							</AccordionItemPanel>
						</AccordionItem>
					))}
				</Accordion>
			</div>

			<Script
				id="faq-script"
				type="application/ld+json"
				dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
			/>
		</>
	);
};

export default FAQ;
